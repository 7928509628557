// Vendors
import React, {
  useRef,
  useState,
  useCallback,
} from 'react';
import ReactTimer from 'react-idle-timer';
import { Trans, t } from '@lingui/macro';

// Redux and router hook
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';

// Material UI Component
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import useLogout from '../../hooks/useLogout';
import { userActivityLog } from '../../api/customers';
import useSnackbarContext from '../../hooks/useSnackbarContext';

// Constants
import {
  SESSION_TIMEOUT_TIME, SESSION_VARIABLE,
  SESSION_VARIABLE_USER_ACTION,
  SESSION_WARNING_TIME_SECONDS,
  AS_USER_ID,
  AS_USER_SESSION,
} from '../../constants/appConstants';
import { REDIRECT_TO_HOME } from '../../constants/routeConstants';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '27%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  timerContainer: {
    textAlign: 'center',
    margin: '30px 0',
    lineHeight: 1.05,
    color: theme.palette.primary.main,
  },
  buttonLogout: {
    marginRight: '10px',
  },
}));

const getDateDiffInSeconds = (startDate, endDate) => (endDate - startDate) / 1000;

const IdleTimer = () => {
  const navigate = useNavigate();
  const { REACT_APP_WP_URL } = process.env;
  const classes = useStyles();
  const { logout } = useLogout();
  const idleTimerRef = React.useRef(null);
  const warningTimerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timer, setTimer] = useState(SESSION_WARNING_TIME_SECONDS);
  const [isTimer, setIsTimer] = useState(false);
  const viewAsUserId = localStorage.getItem(AS_USER_ID) || '';
  const viewAsUserSessionId = localStorage.getItem(AS_USER_SESSION) || '';
  const { error } = useSnackbarContext();

  const { mutateAsync: mutateUserActivityLog } = useMutation(
    userActivityLog,
    {
      onError: () => {
        error(t`Failed to exit user activity`);
      },
    },
  );
  const resetModuleTimmer = () => {
    setIsTimer(false);
    setTimer(SESSION_WARNING_TIME_SECONDS);
    setIsModalOpen(false);
    localStorage.setItem(SESSION_VARIABLE_USER_ACTION, null);
  };
  const handleLogout = useCallback(async () => {
    if (viewAsUserId.length) {
      mutateUserActivityLog({
        data: {
          logEvent: 'impersonate_exit', entity: 'users', entityKey: viewAsUserId, sessionId: viewAsUserSessionId,
        },
      });
    }
    resetModuleTimmer();
    clearTimeout(warningTimerRef.current);
    logout({ returnTo: `${REACT_APP_WP_URL}` });
    localStorage.clear();
  }, [REACT_APP_WP_URL, logout, mutateUserActivityLog, viewAsUserId, viewAsUserSessionId]);

  const isUserTimedOut = useCallback((timeStamp) => timeStamp
  && getDateDiffInSeconds(new Date(timeStamp), new Date())
  >= (SESSION_TIMEOUT_TIME * SESSION_WARNING_TIME_SECONDS + SESSION_WARNING_TIME_SECONDS)
  && handleLogout(true), [handleLogout]);

  React.useEffect(() => {
    const sessionActiveDate = idleTimerRef.current.getLastActiveTime();
    isUserTimedOut(sessionActiveDate);
    if (isTimer && timer > 0) {
      warningTimerRef.current = setTimeout(() => setTimer(timer - 1), 1000);
    } else if (isTimer && timer === 0) {
      handleLogout(true);
    } else {
      setIsTimer(false);
      clearTimeout(warningTimerRef.current);
    }
    return () => warningTimerRef.current && clearTimeout(warningTimerRef.current);
  }, [isUserTimedOut, handleLogout, isTimer, timer]);

  React.useEffect(() => {
    const checkUserAunticated = (event) => {
      if (event.key === SESSION_VARIABLE && event.oldValue && !event.newValue) {
        navigate(REDIRECT_TO_HOME);
      } else if (event.key === SESSION_VARIABLE_USER_ACTION && event.newValue === 'continue') {
        clearTimeout(warningTimerRef.current);
        resetModuleTimmer();
        idleTimerRef.current.reset();
      } else if (event.key === SESSION_VARIABLE_USER_ACTION) {
        idleTimerRef.current.reset();
      }
    };
    const sessionActiveDate = localStorage.getItem(SESSION_VARIABLE_USER_ACTION);
    isUserTimedOut(sessionActiveDate);
    window.addEventListener('storage', checkUserAunticated);
    return () => window.removeEventListener('storage', checkUserAunticated);
  }, [navigate, handleLogout, isUserTimedOut]);

  const handleContinue = () => {
    clearTimeout(warningTimerRef.current);
    resetModuleTimmer();
    localStorage.setItem(SESSION_VARIABLE_USER_ACTION, 'continue');
  };

  const appIsIdle = () => {
    setIsModalOpen(true);
    setIsTimer(true);
  };

  const appIsNotIdle = () => {
    localStorage.setItem(SESSION_VARIABLE_USER_ACTION, new Date());
  };

  const getTimeLeft = () => {
    const dateObj = new Date(timer * 1000);
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getSeconds();

    return `${minutes.toString().padStart(2, '0')
    }:${seconds.toString().padStart(2, '0')}`;
  };

  const body = (
    <>
      <DialogContent>
        <DialogContentText>
          <Grid
            container
            direction="column"
          >
            <Grid item>
              <Trans>Your session is about to expire</Trans>
            </Grid>
            <Grid item>
              <Trans>You will be logged out in {SESSION_WARNING_TIME_SECONDS} seconds.</Trans>
            </Grid>
            <Grid item classes={{ root: classes.timerContainer }}>
              {getTimeLeft()}
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleLogout(false)}
          variant="outlined"
          color="primary"
          data-testid="Logout"
        >
          <Trans>Logout</Trans>
        </Button>
        <Button
          onClick={handleContinue}
          data-testid="ContinueSession"
          variant="contained"
          color="primary"
        >
          <Trans>Continue Session</Trans>
        </Button>
      </DialogActions>
    </>
  );

  return (
    <>
      <ReactTimer
        ref={idleTimerRef}
        onIdle={appIsIdle}
        onAction={appIsNotIdle}
        timeout={SESSION_TIMEOUT_TIME * SESSION_WARNING_TIME_SECONDS * 1000}
      />{
        isModalOpen && (
          <Dialog
            open={isModalOpen}
            keepMounted
            aria-labelledby="idle-timer-modal"
            data-testid="idle-timer-modal"
            PaperProps={{
              variant: 'outlined',
            }}
          >{body}
          </Dialog>
        )
      }

    </>
  );
};

export default IdleTimer;
