// Vendor
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff',
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}));

export default useStyles;
