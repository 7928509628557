import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  actionContainer: {
    padding: '0px 24px 24px',
  },
}));

const WarningDialog = ({
  open, onClose, onSubmit, warningMessage, postiveButtonText, cancelButtonText,
  title, showCancelButton,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="warning-dialog"
      data-testid="warning-dialog"
      PaperProps={{
        variant: 'outlined',
      }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {warningMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{
        root: classes.actionContainer,
      }}
      >
        {showCancelButton && (
          <Button
            onClick={() => onClose()}
            variant="outlined"
            color="primary"
          >
            {cancelButtonText}
          </Button>
        )}
        <Button
          onClick={() => onSubmit()}
          variant="contained"
          color="primary"
        >
          {postiveButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WarningDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  postiveButtonText: PropTypes.string,
  warningMessage: PropTypes.string,
  cancelButtonText: PropTypes.string,
  title: PropTypes.string,
  showCancelButton: PropTypes.bool,
};

WarningDialog.defaultProps = {
  warningMessage: t`On cancel changes will be lost, are you sure you want to continue?`,
  postiveButtonText: t`YES`,
  cancelButtonText: t`NO`,
  title: '',
  showCancelButton: true,
};

export default WarningDialog;
