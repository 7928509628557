export default {
  MuiTabPanel: {
    root: {
      padding: '20px 0',
    },
  },
  MuiTabs: {
    indicator: {
      borderRadius: 2,
    },
  },
  MuiTab: {
    root: {
      '@media (min-width: 600px)': {
        minWidth: 80,
      },
      textTransform: 'none',
      fontWeight: 'normal',
      padding: 6,
    },
  },
};
