import React, { useState, useEffect, useRef } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Can from '../Can';
import { useStyles } from './styles';
import { CLIENT_ID } from '../../constants/appConstants';
import useLogout from '../../hooks/useLogout';

function ClientMenu({
  title,
  clientRoleDetails,
  children,
  className,
}) {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();
  const { logout } = useLogout();

  const menuItemClick = (event, url, historyParamter, setChangePassDialog) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (url) {
      navigate(url, { state: { ...historyParamter?.state } });
    }
    if (event.target.id === 'go_to_zosi_cms') {
      localStorage.removeItem(CLIENT_ID);
    }
    if (event.target.id === 'login') {
      loginWithRedirect();
    }
    if (event.target.id === 'logout') {
      logout({ returnTo: window.location.origin });
    }
    if (event.target.id === 'password') {
      setChangePassDialog();
    }
    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={className || classes.root} onMouseLeave={() => setOpen(false)}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={() => setOpen(true)}
        onMouseOver={() => setOpen(true)}
        disableRipple
      >
        {title}
        {children }
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        onMouseLeave={() => setOpen(false)}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  classes={{
                    root: classes.menuListContainer,
                  }}
                  onMouseLeave={() => setOpen(false)}
                >
                  {clientRoleDetails.map(({
                    menuItem, permissions, url, href, historyParamter,
                    id, setChangePassDialog, viewAsCallerUser = true, divider,
                  }) => (
                    <Can
                      permissions={permissions}
                      key={menuItem}
                      yes={() => viewAsCallerUser && (
                        <MenuItem
                          onClick={(event) => menuItemClick(event,
                            url, historyParamter, setChangePassDialog)}
                          component="a"
                          href={href}
                          target="_blank"
                          classes={{
                            root: classes.menuItem,
                          }}
                          id={id}
                          divider={divider}
                        >
                          {menuItem}
                        </MenuItem>
                      )}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
ClientMenu.defaultProps = {
  title: '',
  historyParamter: null,
  className: null,
};

ClientMenu.propTypes = {
  title: PropTypes.string,
  clientRoleDetails: PropTypes.arrayOf(
    PropTypes.shape({
      menuItem: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  children: PropTypes.node.isRequired,
  historyParamter: PropTypes.shape({}),
  className: PropTypes.node,
};

export default ClientMenu;
