import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  infoArea: {
    marginBottom: '15px',
  },
  container: {
    padding: '0px 8px 0px 8px',
  },
  dataItemLabel: {
    opacity: '0.6',
  },
  datePicker: {
    '& input': {
      paddingBottom: '5px',
      paddingTop: '10px',
    },
    '& button': {
      padding: '0px',
    },
  },
  subscriptionDate: {
    display: 'inline-grid',
  },
  infoLabel: {
    marginBlock: '10px',
  },
}));

export default useStyles;
