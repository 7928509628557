// Vendors
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '16px',
  },
  content: {
    textAlign: 'center',
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    paddingBottom: '25px',
  },
  itemTitle: {
    fontSize: '24px',
    fontWeight: '500',
  },
  itemDescription: {
    lineHeight: '1.38',
    padding: '10px 0',
  },
  buttonLabel: {
    textTransform: 'none',
    textDecoration: 'underline',
  },
  buttonRoot: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
}));

export default useStyles;
