// Vendors
import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

import useAppContext from '../../hooks/useAppContext';

// Style
import styles from './styles';

const ErrorPage = ({ handleOnRetryClick }) => {
  const classes = styles();
  const { theme } = useAppContext();
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      classes={{ root: classes.root }}
      data-testid="ErrorPage-page"
    >
      <Grid
        item
        classes={{ item: classes.content }}
      >
        <img
          alt="Under development"
          className={classes.image}
          src={theme ? '/error_dark.svg' : '/error.svg'}
        />
        <Grid item classes={{ item: classes.itemTitle }}>
          <Trans>Something went wrong</Trans>
        </Grid>
        <Grid item classes={{ item: classes.itemDescription }}>
          <Trans>We’re working on it and we’ll get it fixed as soon as we can.</Trans>
        </Grid>
        <Grid item classes={{ item: classes.itemDescription }}>
          <Button onClick={handleOnRetryClick} classes={{ label: classes.buttonLabel, root: classes.buttonRoot }} color="primary"><Trans>Try Again</Trans></Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

ErrorPage.propTypes = {
  handleOnRetryClick: PropTypes.func.isRequired,
};

export default ErrorPage;
