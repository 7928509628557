import { i18n } from '@lingui/core';
import {
  de, en, es, fr, pt, ca,
} from 'make-plural/plurals';

i18n.loadLocaleData('en-US', { plurals: en });
i18n.loadLocaleData('es-MX', { plurals: es });
i18n.loadLocaleData('en-GB', { plurals: en });
i18n.loadLocaleData('es-US', { plurals: es });
i18n.loadLocaleData('pt-BR', { plurals: pt });
i18n.loadLocaleData('de-DE', { plurals: de });
i18n.loadLocaleData('fr-FR', { plurals: fr });
i18n.loadLocaleData('fr-CA', { plurals: ca });

export const locales = {
  'en-US': 'English (United States)',
  'en-GB': 'English (United Kingdom)',
  'es-MX': 'Español (México)',
  'es-US': 'Español (Estados Unidos)',
  'pt-BR': 'Portugues (brasil)',
  'de-DE': 'Deutsches (Deutschland)',
  'fr-CA': 'Français (Canada)',
  'fr-FR': 'français (France)',
};

export const defaultLocale = 'en-US';

export async function dynamicActivate(locale) {
  const { messages } = await import(`@lingui/loader!../locales/${locale}.po`);
  i18n.load(locale, messages);
  i18n.activate(locale, [locale]);
}

// these are not exported as macros, so wraping them from here for eou
export function date(value, format) {
  return i18n.date(value, format);
}
export function number(value, format) {
  return i18n.number(value, format);
}
