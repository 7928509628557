import { createMuiTheme } from '@material-ui/core';
import darkTheme from './darkTheme';
import typography from './darkTheme/typography';

const lightTheme = {
  palette: {
    type: 'light',
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  typography,
};

const getTheme = (isDarkTheme) => (
  isDarkTheme ? createMuiTheme(darkTheme) : createMuiTheme(lightTheme)
);

export default getTheme;
