import MuiButton from './MuiButton';
import MuiPopover from './MuiPopover';
import MuiFilledInput from './MuiFilledInput';
import Tabs from './Tabs';

export default {
  MuiButton,
  MuiPopover,
  MuiFilledInput,
  ...Tabs,
};
