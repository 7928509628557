export default {
  label: {
    textTransform: 'none',
  },
  outlinedPrimary: {
    marginRight: '15px',
  },
  fullWidth: {
    margin: '5px 0',
  },
};
