import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  buttonLabel: {
    textTransform: 'none',
    marginLeft: '8px',
  },
  buttonRoot: {
    '&:hover': {
      backgroundColor: 'inherit',
      textDecoration: 'underline',
    },
    padding: '0px',
    minWidth: '46px',
  },
  contentHeader: {
    letterSpacing: '0.15px',
  },
  dialogTitle: {
    fontSize: '20px',
  },
  tableCell: {
    border: 0,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  column1: {
    verticalAlign: 'top',
    letterSpacing: '0.4px',
    opacity: '0.87',
    fontWeight: 'normal',
    fontSize: '12px',
    width: '30%',
    color: '#ffffff',
  },
  column2: {
    fontWeight: 'normal',
    fontSize: '12px',
    wordWrap: 'break-word',
    color: 'rgba(255, 255, 255, 0.6)',
  },
  columnHeader: {
    fontSize: '14px',
  },

  paperContainer: {
    width: '576px',
    maxWidth: '576px',
    padding: '15px 5px 24px 15px',
    minHeight: '375px',
    height: 'auto',
    maxHeight: '450px',
  },
  tableContainer: {
    marginLeft: '-15px',
    marginTop: '-15px',
  },
}));

export default useStyles;
