import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@material-ui/core';
import styles from '../../styles';

export default function Loading({ isLoading, withBackDrop }) {
  const classes = styles();
  return (
    withBackDrop ? (
      <Backdrop open={isLoading} className={classes.backdrop} data-testid="LoadingIndicator">
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : (
      <div className={classes.circularProgress}><CircularProgress color="inherit" /></div>
    )
  );
}

Loading.propTypes = {
  isLoading: PropTypes.bool,
  withBackDrop: PropTypes.bool,
};

Loading.defaultProps = {
  isLoading: false,
  withBackDrop: true,
};
