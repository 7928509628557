import { createMuiTheme, colors } from '@material-ui/core';

const zIndex = {
  appBar: 1200,
  drawer: 1100,
};

const lightTheme = {
  palette: {
    type: 'light',
    primary: {
      main: colors.orange[700],
    },
    secondary: {
      main: colors.brown[600],
    },
  },
  zIndex,
};

const darkTheme = {
  palette: {
    type: 'dark',
    primary: {
      dark: colors.purple[500],
      main: colors.purple[300],
    },
    secondary: {
      main: colors.teal[300],
    },
  },
  zIndex,
};

const getTheme = (isDarkTheme) => (
  isDarkTheme ? createMuiTheme(darkTheme) : createMuiTheme(lightTheme)
);

export default getTheme;
